import { StyledOptions } from "@emotion/styled";
import * as Material from "@mui/material";
import { IS_DEV_ENVIRONMENT, IS_STAGE_ENVIRONMENT } from "../../constants";

/**
 * Props for components with Area 51 specific variations
 */
interface IsA51UserProps {
	/**
	 * Styles with blue accent instead of environment specific accent
	 */
	isA51User: boolean;
}

/**
 * A reusable styled options object for components with Area 51 specific styles
 */
const a51UserStyledOptions: StyledOptions = {
	shouldForwardProp: (prop: PropertyKey) => prop !== "isA51User"
};

/**
 * A version of Stellantis blue, lightened for contrast
 * Used instead of environment specific accent color when user belongs to an Area 51 group
 */
const area51Blue = "#3b57c9";

/**
 * Dynamic icon colors that change with environment
 * Either cyan, orange, or yellow
 */
export const accentColor = IS_DEV_ENVIRONMENT
	? "#eca935"
	: IS_STAGE_ENVIRONMENT
	? "#e94e24"
	: "#43aaa0";

/**
 * Width that nav is when in collapsed state in rem
 * Value shared with app styling and from themes.scss
 */
export const collapsedNavWidth = 6.25; // 100px

/**
 * Width that nav expands to when in open state
 */
const openNavWidth = 17.188; // 275px

/*
 * Height for container containing nav icon and it's label
 */
const navItemHeight = 3.25; // 52px

/**
 * Height for bottom bar on mobile screens
 */
export const bottomBarHeight = 4.25; // 68px

/**
 * Custom size for mobile icons. A bit bigger than MUI "large"
 */
export const mobileIconSize = 2.5; // 40px

/**
 * Width for mobile drawer
 */
export const mobileDrawerWidth = "235px";

/**
 * Width for desktop drawer
 */
export const desktopDrawerWidth = "300px";

/**
 * Width for the search bar
 */
export const searchBarWidth = "18.625rem";

/**
 * The z index of the nav drawer
 */
const NAV_DRAWER_Z_INDEX = 1200;

/**
 * The z index of the app bar (necessary for search bar to overlap on expansion)
 */
export const APP_BAR_Z_INDEX = NAV_DRAWER_Z_INDEX + 1;

/**
 * Given a theme, returns a color for the nav background
 * Unless grey variant specified, returns grey[900]
 * @param variant the specified variation of grey, get a lighter shade of grey by passing in less than 900
 * @returns the color value
 */
const getNavBackground = (theme: Material.Theme, greyVariant: keyof Material.Color = 900): string =>
	theme.palette.grey[greyVariant];

/**
 * The outermost wrapper to set styles used throughout the component
 */
export const LeftNavigationWrapper = Material.styled(
	"div",
	a51UserStyledOptions
)<IsA51UserProps>(({ isA51User }) => ({
	fontFamily: `"Encode Sans Expanded", "Lato", sans-serif`,
	"& svg": {
		color: isA51User ? area51Blue : accentColor // All icons are accent color unless overridden
	}
}));

/**
 * The bottom bar on medium and down screens
 */
export const BottomBar = Material.styled("div")({
	width: "100%",
	height: "100%",
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	padding: "0 8%",
	"& svg": {
		fontSize: mobileIconSize + "rem" // a bit bigger than mui "large"
	}
});

export const LeftBar = Material.styled("div")({
	display: "flex",
	flexDirection: "column",
	height: "100%",
	width: "100%"
});

/**
 * The wrapper app bar for both the small bottom bar and left vertical bar
 */
export const AppBarWrapper = Material.styled(Material.AppBar, {
	shouldForwardProp: (prop) => prop !== "isMdAndDown"
})<{ isMdAndDown: boolean }>(({ theme, isMdAndDown }) => ({
	position: "fixed",
	top: isMdAndDown ? "auto" : "0",
	bottom: isMdAndDown ? "0" : "auto",
	left: 0,
	height: isMdAndDown ? bottomBarHeight + "rem" : "100%",
	width: isMdAndDown ? "100%" : collapsedNavWidth + "rem",
	zIndex: APP_BAR_Z_INDEX, // places above drawer
	boxShadow: "none",
	backgroundColor: getNavBackground(theme),
	/**
	 * MUI adds 15px padding to accommodate for the scroll bar when dialogs are open
	 * This can be resolved with disableScrollLock prop on the dialog, but since we don't have a single
	 * reusable dialog, just overriding it here with the !important rule
	 */
	padding: "0 !important",
	backgroundImage: "none", // has a default background image
	"&.MuiPaper-root": {
		padding: 0
	}
}));

/**
 * The left drawer that houses the menu options for medium and up screens
 */
export const LeftDrawer = Material.styled(Material.Drawer, {
	shouldForwardProp: (prop) => prop !== "isMdAndDown"
})<{ isMdAndDown: boolean }>(({ theme, isMdAndDown }) => ({
	display: "flex",
	overflow: "hidden",
	flexDirection: "column",
	position: "relative",
	"& .MuiPaper-root": {
		left: "auto",
		flexDirection: "column",
		overflow: "visible",
		backgroundColor: getNavBackground(theme)
	},
	"& .MuiDrawer-paper": {
		[theme.breakpoints.down("sm")]: {
			width: mobileDrawerWidth
		},
		width: desktopDrawerWidth,
		paddingBottom: isMdAndDown ? bottomBarHeight + "rem" : 0 // adds bit of padding so elements scroll above bottom bar
	}
}));

/**
 * A column oriented flexbox to wrap sections of the vertical nav
 * Top - search, home link
 * Middle - Scenes
 * Bottom - Support, profile preferences, region select
 */
export const NavSection = Material.styled("div", {
	shouldForwardProp: (prop) => prop !== "isOpen"
})<{ isOpen?: boolean }>(({ theme, isOpen }) => ({
	display: "flex",
	flexDirection: "column",
	scrollbarColor: `${getNavBackground(theme, 800)} ${getNavBackground(theme)}`,
	"&.subscene-list": {
		width: isOpen ? "100%" : 0,
		marginRight: "6px",
		transition: "width 600ms",
		whiteSpace: "nowrap",
		overflowY: "auto",
		overflowX: "hidden"
	}
}));

/**
 * Styled text ADA logo in the brand font
 * isA51User is optional because this styles the region typography and ADA typography
 */
export const AdaLogoTypography = Material.styled(
	Material.Typography,
	a51UserStyledOptions
)<{ isA51User?: boolean }>(({ isA51User = false }) => ({
	fontSize: "2.25rem",
	letterSpacing: "0.063rem",
	textShadow: `0.125rem 0 ${isA51User ? area51Blue : accentColor}`, // gives bold appearance
	color: isA51User ? area51Blue : accentColor,
	fontFamily: `"Encode Sans Expanded", "Lato", sans-serif`,
	textAlign: "center",
	"&.region": {
		lineHeight: "1",
		fontSize: "2.1rem",
		"&:hover": {
			fontSize: "2.2rem"
		}
	}
}));

/**
 * A div of set height in the dynamic accent color
 */
export const EnvFlag = Material.styled(
	"div",
	a51UserStyledOptions
)<IsA51UserProps>(({ isA51User }) => ({
	backgroundColor: isA51User ? area51Blue : accentColor,
	width: "100%",
	height: "1.5rem"
}));

/**
 * A bold but small font size label for the environment
 */
export const EnvLabel = Material.styled(Material.Typography)({
	color: "white",
	fontSize: "1.25rem",
	letterSpacing: "0.063rem",
	textShadow: "0.094rem 0 white", // gives bold appearance
	textAlign: "center",
	lineHeight: 1.2
});

/**
 * A visual, rounded wrapper for the input base
 */
export const SearchBar = Material.styled("div", {
	shouldForwardProp: (prop) => prop !== "isOpen" && prop !== "isA51User"
})<{ isOpen: boolean; isA51User: boolean }>(({ theme, isOpen, isA51User }) => ({
	backgroundColor: theme.palette.grey[800],
	borderRadius: "1.875rem",
	height: "2.688rem",
	display: "flex",
	alignItems: "center",
	transition: "width 500ms",
	width: isOpen ? searchBarWidth : 0,
	position: "absolute",
	marginTop: "0.25rem",
	left: "1.375rem", // moves bar to expand left of the search icon
	overflow: "hidden",
	"& input": {
		color: "white"
	},
	"& .MuiSwitch-root": {
		margin: "auto 0.25rem auto 0",
		"& .MuiSwitch-switchBase.Mui-checked": {
			backgroundColor: isA51User ? area51Blue : accentColor
		},
		"& .MuiSwitch-track": {
			backgroundColor: "#ffff"
		}
	}
}));

/**
 * A wrapping div with set height for top section of nav
 * Also gives position sticky so content below can scroll
 * Can override height with height override prop
 * @component div
 */
export const HeaderWrapper = Material.styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-end",
	position: "sticky",
	top: 0,
	zIndex: 1,
	backgroundColor: getNavBackground(theme),
	[theme.breakpoints.down("sm")]: {
		height: "70px",
		minHeight: "70px"
	},
	[theme.breakpoints.only("md")]: {
		height: "100px",
		minHeight: "100px"
	},
	[theme.breakpoints.up("md")]: {
		height: "145px",
		minHeight: "145px"
	}
}));

/**
 * Wrapper around nav items for consistent height. Row layout for search wrapper
 * Styles caption under icon, icon color, and hover state
 */
export const NavItem = Material.styled("div", {
	shouldForwardProp: (prop) => prop !== "searchItem" // search wrapper needs to be row, baseline aligned
})<{ searchItem?: boolean }>(({ searchItem }) => ({
	maxHeight: navItemHeight + "rem",
	minHeight: navItemHeight + "rem",
	height: navItemHeight + "rem",
	width: "100%",
	display: "flex",
	flexWrap: "nowrap",
	flexDirection: searchItem ? "row" : "column",
	alignItems: searchItem ? "baseline" : "center",
	justifyContent: "center",
	cursor: "pointer",
	"& .scene-caption": {
		textAlign: "center",
		lineHeight: "0.75rem",
		wordBreak: "break-word",
		fontSize: "0.75rem",
		letterSpacing: "-0.047rem", // Squishes caption to slim left nav w/o decreasing font size
		margin: "0 0.125rem 0.125rem 0.125rem",
		color: "transparent"
	},
	"& svg.nav-icon": {
		fontSize: "2.4rem"
	},
	// turn icon captions to visible when hovering
	"&:hover, &.highlighted": {
		"& .scene-caption": {
			color: "white",
			opacity: ".9"
		},
		// hover effect
		"& svg.nav-icon, p.nav-icon": {
			backgroundColor: "rgba(255, 255, 255, 0.1)",
			width: "65%",
			borderRadius: "1.563rem",
			// icons enlarge on hover
			fontSize: "2.6rem",
			"&.region": {
				fontSize: "2.2rem"
			}
		}
	}
}));

/**
 * The hr on both collapsed menu and expanded
 */
export const NavDivider = Material.styled(Material.Divider)({
	margin: "0.5rem 0",
	width: "100%"
});

/**
 * The styled links to individual scenes in the expanded nav
 */
export const MenuOptionLabel = Material.styled(Material.Link)(({ theme }) => ({
	color: "white !important",
	width: openNavWidth + "rem",
	margin: "0.875rem 0 0.875rem 0.875rem",
	lineHeight: "1.3",
	// slightly different styles to align with the ADA logo
	"&.env-label": {
		lineHeight: "3.375rem",
		whiteSpace: "nowrap",
		margin: "0.375rem 0 0 0.75rem"
	},
	"&:hover": {
		color: `${theme.palette.primary.hover} !important`
	}
}));

/**
 * Wrapper for MenuOptionLabel, gives hover effect
 * Not used for environment label or mobile screens
 */
export const MenuOptionWrapper = Material.styled("div")<{
	isAccordion?: boolean;
	isMdAndDown?: boolean;
}>(({ isAccordion }) => ({
	width: "95%",
	minHeight: "2.813rem",
	cursor: "pointer",
	borderRadius: "1.875rem",
	pointerEvents: isAccordion ? "none" : "auto",
	"&:hover": {
		backgroundColor: "rgba(255, 255, 255, 0.1)"
	},
	"& a": {
		lineHeight: "2.813rem"
	}
}));

/**
 * The popover menu, has a consistent width even for small menu items
 * Used for profile preferences and region selection
 */
export const PopoverMenu = Material.styled(Material.Popover)(({ theme }) => ({
	"& .MuiPaper-root": {
		width: openNavWidth + "rem"
	},
	// default background color is dark blue, does not contrast
	// overriding with default hover background color
	"& .Mui-selected": {
		backgroundColor:
			theme.palette.mode === "dark"
				? "rgba(255, 255, 255, 0.08) !important"
				: "rgba(0, 0, 0, 0.04) !important"
	}
}));

/**
 * A conditionally styled material avatar image
 * If area 51 user, image is given set height
 */
export const Avatar = Material.styled(
	Material.Avatar,
	a51UserStyledOptions
)<IsA51UserProps>(({ isA51User }) => ({
	height: "1em",
	width: isA51User ? "2em" : "1em",
	paddingRight: isA51User ? ".5em" : 0,
	"& img": {
		height: isA51User ? ".6em" : "inherit",
		width: isA51User ? "auto" : "inherit"
	}
}));

/**
 * A styled accordion
 * Strips margin and padding to maintain consistent styles with other nav items
 * other nav panels
 * @component Material.Accordion
 */
export const FavoritesAccordion = Material.styled(Material.Accordion)({
	"& .MuiAccordionSummary-root": {
		paddingLeft: 0
	},
	"& .MuiAccordionSummary-content": {
		margin: 0
	}
});

/**
 * An autocomplete styled to include a switch element and collapse behavior
 * @component Material.Autocomplete
 */
export const SearchAutocomplete = Material.styled(Material.Autocomplete)({
	display: "flex",
	justifyContent: "center",
	width: searchBarWidth,
	"& .MuiAutocomplete-root": {
		overflow: "hidden"
	},
	"& .MuiInputBase-root": {
		flexWrap: "nowrap"
	}
});
