import React from "react";
import { ErrorBoundary } from ".";

/**
 * Wraps a component with {@link ErrorBoundary}.
 * @param Component - the component to wrap with {@link ErrorBoundary}.
 */
export const withErrorBoundary = (Component: React.ComponentType): React.FC => () => (
	<ErrorBoundary>
		<Component />
	</ErrorBoundary>
);
