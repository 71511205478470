import { Checkbox, FormControlLabel, FormLabel, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { $enum } from "ts-enum-util";
import { Regions } from "../../utils/regionUtils";
import { Roles } from "../../utils/roleUtils";
import adaUsersTable from "./adaUsersTable.png";
import * as Styled from "./landingPageStyles";

const COMPONENT_NAME = "AccessRequestView";

/**
 * Props interface for this component.
 */
interface AccessRequestViewProps {
	/**
	 * Called when the user submits their request.
	 * @param userGroup - the organization the user belongs to.
	 * @param selectedRoles - the user selected role.
	 * @param selectedRegions - the region.
	 * @param businessJustification - the business justification.
	 */
	onSubmit(
		userGroup: string,
		selectedRoles: Roles[],
		selectedRegions: Regions[],
		businessJustification: string
	): void;
	/**
	 * Determines if the user is requesting a change of access or initial access
	 */
	isRequestingChange: boolean;
	/**
	 * The user's existing permissions, if any
	 */
	existingPermissions?: string[];
}

/**
 * The view to allow new users to request access for the portal
 * @param props - component props.
 * @return the rendered component.
 */
const AccessRequestView: React.FC<AccessRequestViewProps> = (props) => {
	const [selectedRegions, setSelectedRegions] = React.useState<Array<Regions>>([]);
	const [selectedRoles, setSelectedRoles] = React.useState<Array<Roles>>([]);
	const [businessJustification, setBusinessJustification] = React.useState<string>("");
	const [userGroup, setUserGroup] = React.useState<string>("");

	const isFormValid =
		!_.isEmpty(selectedRoles) &&
		_.size(businessJustification.trim()) > 0 &&
		!_.isEmpty(selectedRegions) &&
		_.size(userGroup.trim()) > 0;

	return (
		<Styled.AccessRequestView>
			<Styled.AccessRequestForm>
				<FormLabel required={true}>Select the region(s) you need access to</FormLabel>
				{$enum(Regions).map((region) => {
					return (
						<FormControlLabel
							key={region}
							control={
								<Checkbox
									onChange={(event) => {
										if (event.target.checked) {
											setSelectedRegions([...selectedRegions, region]);
										} else {
											setSelectedRegions(
												_.filter(selectedRegions, (regions) => {
													return regions !== region;
												})
											);
										}
									}}
									value={region}
								/>
							}
							label={region}
						/>
					);
				})}
			</Styled.AccessRequestForm>
			<Typography variant="h6" gutterBottom={true} paddingTop={"2rem"}>
				User Role to Access Matrix
			</Typography>
			<Styled.AccessMatrixImage
				src={adaUsersTable}
				alt={"Table showing user role to access matrix"}
			/>
			<Styled.AccessRequestForm>
				<FormLabel required={true}>
					Select the role(s) that best fit your needs *[Look at "User Role to Access
					Matrix" above]
				</FormLabel>
				{$enum(Roles).map((role) => {
					return (
						<FormControlLabel
							key={role}
							control={
								<Checkbox
									onChange={(event) => {
										if (event.target.checked) {
											setSelectedRoles([...selectedRoles, role]);
										} else {
											setSelectedRoles(
												_.filter(selectedRoles, (roles) => {
													return roles !== role;
												})
											);
										}
									}}
									value={role}
								/>
							}
							label={role}
						/>
					);
				})}
			</Styled.AccessRequestForm>
			<Styled.TopSpacer>
				<TextField
					sx={{ width: { sm: 250, md: 500 } }}
					required={true}
					label={"What group do you belong to? (e.g. Remote Vehicle Management)"}
					type="text"
					variant="filled"
					value={userGroup}
					onChange={(event) => {
						setUserGroup(event.target.value);
					}}
				/>
			</Styled.TopSpacer>
			<Styled.TopSpacer>
				<TextField
					sx={{ width: { sm: 250, md: 500 } }}
					required={true}
					multiline={true}
					minRows={2}
					label={"What is the business justification for this access request?"}
					type="text"
					variant="filled"
					value={businessJustification}
					onChange={(event) => {
						setBusinessJustification(event.target.value);
					}}
				/>
			</Styled.TopSpacer>

			<Styled.AccessRequestSubmitButton
				variant="contained"
				color="primary"
				fullWidth={false}
				disabled={!isFormValid}
				onClick={() => {
					props.onSubmit(
						userGroup,
						selectedRoles,
						selectedRegions,
						businessJustification
					);
				}}
			>
				Submit
			</Styled.AccessRequestSubmitButton>
		</Styled.AccessRequestView>
	);
};
AccessRequestView.displayName = COMPONENT_NAME;
export default AccessRequestView;
