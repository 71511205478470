import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";

/**
 * Name for this Component.
 */
const COMPONENT_NAME = "DialogTransition";

/**
 * The transition component for a full screen dialog.
 */
export const DialogTransition = React.forwardRef(
	(
		props: TransitionProps & { children: React.ReactElement<any, any>},
		ref: React.Ref<unknown>
	) => {
		return <Slide direction="up" ref={ref} {...props} />;
	}
);
DialogTransition.displayName = COMPONENT_NAME;
