import { Link, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { Component, ErrorInfo, ReactNode } from "react";
import { renderCustomHead } from "../../renderCustomHead";
import "./ErrorBoundary.scss";

/**
 * Name of this component.
 */
const COMPONENT_NAME = "ErrorBoundary";

/**
 * Props interface for this component.
 */
interface Props {
	/**
	 * Children of this component.
	 */
	children: ReactNode;
}

/**
 * State interface for this component.
 */
interface State {
	/**
	 * The error
	 */
	error: undefined | Error;
	/**
	 * The error stack message.
	 */
	componentStack: string;
}

/**
 * The ErrorBoundary - helps to navigate to custom error page if something goes wrong
 * @param props - component props.
 * @return The rendered error boundary component.
 */
export class ErrorBoundary extends Component<Props, State> {
	static displayName: string;
	/**
	 * @inheritdoc
	 */
	constructor(props: Props) {
		super(props);
		this.state = {
			error: undefined,
			componentStack: ""
		};
	}
	/**
	 * @inheritdoc
	 */
	public static getDerivedStateFromError(error: Error): State {
		return { error: error, componentStack: "" };
	}
	/**
	 * @inheritdoc
	 */
	public componentDidCatch(error: Error, errorInformation: ErrorInfo): any {
		this.setState({
			componentStack: errorInformation.componentStack || ""
		});
	}
	/**
	 * @inheritdoc
	 */
	public render(): React.ReactNode {
		if (this.state.error) {
			return (
				<div className={COMPONENT_NAME}>
					{renderCustomHead(`Error`)}
					<div className={`${COMPONENT_NAME}__boundary-error-view`}>
						<Typography variant={"h4"}>
							An unexpected error occurred. Please report this error to the web
							administrator.
						</Typography>
						<br />
						<TextField
							multiline={true}
							rows="24"
							fullWidth={true}
							variant="outlined"
							value={`${_.toString(this.state.error)} ${this.state.componentStack}`}
						/>
						<br />
						<Typography variant={"h5"}>
							Click <Link href="/">here</Link> to navigate back to the home page.
						</Typography>
					</div>
				</div>
			);
		}
		return this.props.children;
	}
}
ErrorBoundary.displayName = COMPONENT_NAME;
