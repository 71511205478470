import React from "react";
import * as Styled from "./easterEggStyles";

/**
 * Name for this component.
 */
const COMPONENT_NAME = "EasterEgg";

/**
 * An easter egg that makes a small jeep drive across the bottom of the screen.
 */
export const EasterEgg: React.FC = () => {
	return (
		<div className={COMPONENT_NAME}>
			<Styled.Logo />
		</div>
	);
};
EasterEgg.displayName = COMPONENT_NAME;
