import React from "react";

/**
 * Name for this component.
 */
const COMPONENT_NAME = "IsA51UserContext";

/**
 * @returns a boolean if the user belongs to an Area 51 group (starts with "source_")
 */
export const IsA51UserContext = React.createContext<boolean>(false);

IsA51UserContext.displayName = COMPONENT_NAME;
