import _ from "lodash";
import React from "react";
import { Helmet } from "react-helmet";
import { IS_PRODUCTION_ENVIRONMENT } from "./constants";

/**
 * Renders the selected page title.
 * @param pageTitle - title of the page.
 * @param pageDescription - optional page description.
 * @returns the helmet title.
 */
export const renderCustomHead = (pageTitle: string, pageDescription?: string): JSX.Element => {
	const webAppTitle = ["ADA"];

	if (!IS_PRODUCTION_ENVIRONMENT) {
		webAppTitle.push(_.startCase(process.env.REACT_APP_ENVIRONMENT));
	}
	return (
		<Helmet>
			<title>{`${webAppTitle.join(" ")} | ${pageTitle}`}</title>
			<meta name="description" content={pageDescription} />
		</Helmet>
	);
};
