import _ from "lodash";

export interface UserFavorites {
	/**
	 * Recently accessed vins
	 */
	recentVins: Array<string>;
	/**
	 * Pinned vins (up to 10)
	 */
	pinnedVins: Array<string>;
	/**
	 * Pinned policies (up to 10)
	 */
	pinnedPolicies: Array<string>;
}

/**
 * Adds item to favorites list
 * Checks if item is in favorites array and adds it to the beginning if not
 * Sets new array in local storage
 * @param itemToAdd the item to add
 * @param currentList the current list from context
 * @param localStorageName the name of the array in local storage
 * @returns the new favorites array
 */
export function addToFavoriteList(
	currentList: Array<string>,
	localStorageName: string,
	itemToAdd?: string // vins and policies can technically be undefined in /vins and /policies so allowing and handling for that
): Array<string> {
	// If not adding anything, return current list
	if (!itemToAdd) {
		return currentList;
	}
	let newFavoritesArray = [itemToAdd];
	// Append array of previous recent vins with searched vin filtered out to newRecentVins array
	newFavoritesArray = newFavoritesArray
		.concat(currentList.filter((element) => element !== itemToAdd))
		.slice(0, 10);

	// Set new array with first 10 items
	window.localStorage.setItem(localStorageName, JSON.stringify(newFavoritesArray));
	// Return list so context can be updated
	return newFavoritesArray;
}

/**
 * Removes an item from favorites list
 * @param itemToRemove the item to remove from the list
 * @param currentList the current list from context
 * @param localStorageName the name fo the list in local storage
 * @returns the new list
 */
export function removeFromFavoriteList(
	currentList: Array<string>,
	localStorageName: string,
	itemToRemove?: string // vins and policies can technically be undefined in /vins and /policies so allowing and handling for that
): Array<string> {
	if (!itemToRemove) {
		return currentList;
	}
	// Set to copy of array with item filtered out
	const filteredList = currentList.filter((item) => item !== itemToRemove);
	// Set new array in local storage
	window.localStorage.setItem(localStorageName, JSON.stringify(filteredList));
	// Return list so context can be updated
	return filteredList;
}

/**
 * Gets specified favorites list from local storage
 * If list is malformed or cannot be parsed for any reason overwrites previous list with empty array and returns empty array
 * @param localStorageName the name of the list in local storage
 * @returns an array of favorite ids
 */
export function getFavoritesFromLocalStorage(localStorageName: string): Array<string> {
	try {
		const localStorageValue: string | null = window.localStorage.getItem(localStorageName);

		const parsedValue = JSON.parse(localStorageValue ?? "");
		if (!_.isArray(parsedValue)) {
			throw new Error();
		}
		return parsedValue;
	} catch {
		localStorage.setItem(localStorageName, JSON.stringify([]));
		return [];
	}
}
