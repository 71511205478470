import { Container, Grid, Paper, Typography, styled } from "@mui/material";

export const SceneContent = styled(Container)(({ theme }) => ({
	padding: theme.spacing(1),
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	alignSelf: "flex-start"
}));

export const BannerGrid = styled(Grid)(({ theme }) => ({
	padding: theme.spacing(2)
}));

export const CurrentRelease = styled(Paper)(({ theme }) => ({
	padding: theme.spacing(2)
}));

export const IpaText = styled(Typography)(({ theme }) => ({
	fontFamily: theme.typography.fontFamily
}));
