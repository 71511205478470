// NOTE: This is generated code and should NOT be edited for any reason.
// If an error occurs with the generated code, submit a PR into 
// https://github.com/MSDOStoevsky/tag-api-codegen or alter the 
// project locally if forked.
/* eslint-disable  @typescript-eslint/no-empty-interface */
/* eslint-disable  @typescript-eslint/no-unused-vars */
import * as ApiModelTypes from "../apiModelTypes";
import axios, { AxiosResponse, RawAxiosRequestHeaders } from "axios";

/**
 * The service base path.
 */
const BASE_PATH = `${process.env.REACT_APP_API_URL}`;

/**
 * The path parameters for the {@link postAuthToken} function.
 */
export interface postAuthTokenParams {
}

/**
 * The query parameters for the {@link postAuthToken} function.
 */
export interface postAuthTokenQueryParams {
	/**
	 * stub
	 */
	code?: string;
}

/**
 * Retrieve the bearer token
 * @param params - The request parameters (or undefined).
 * @param payload - the request payload (or undefined).
 * @param headers - the request headers.
 * @param signal - optional AbortSignal to cancel this request.
 * @param queryParameters - optional request query parameters (or undefined).
 * @param basePathOverride - optional override to the base path.
 * @returns api request configuration data.
 */
export function postAuthToken(
	params: postAuthTokenParams,
	payload: undefined,
	headers: RawAxiosRequestHeaders,
	signal?: AbortSignal,
	queryParameters?: postAuthTokenQueryParams | undefined,
	basePathOverride?: string
): Promise<AxiosResponse<ApiModelTypes.PostTokenResponse>> {
	return axios.request<ApiModelTypes.PostTokenResponse>({
		headers: headers,
		baseURL: `${basePathOverride || BASE_PATH}`,
		url: `/v1/auth/token`,
		method: "post",
		params: queryParameters,
		data: payload,
		signal: signal
	});
}
