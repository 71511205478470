import { UserPermissions } from "../../api/apiModelTypes";

/**
 * Supported roles
 */
export enum Roles {
	ADMIN = "ADMIN",
	CREATOR = "CREATOR",
	VIEWER = "VIEWER",
	FEATURE_NAMER = "FEATURE_NAMER",
	VIN_MANAGER = "VIN_MANAGER"
}

/**
 * Type alias to enforce us to bind defined Roles to our
 * UserPermissions model so that we are forced to update our app's
 * permission configuration when new Roles are added
 */
type UserRoles = {
	[key in Roles]: UserPermissions[];
};

/**
 * A map of Roles and their permissions
 */
export const USER_ROLES: UserRoles = {
	/**
	 * Admin role.
	 * Feature write, Policy assign, Policy feature write?, Policy read, Policy write,
	 * Stream read/write, User read, User write, Vin profile read, Web access
	 */
	[Roles.ADMIN]: [
		UserPermissions.ADA_API_WEB_ACCESS_NA,
		UserPermissions.ADA_API_WEB_ACCESS_EU,
		UserPermissions.ADA_API_VIN_PROFILE_READ_NA,
		UserPermissions.ADA_API_VIN_PROFILE_READ_EU,
		UserPermissions.ADA_API_POLICY_READ_NA,
		UserPermissions.ADA_API_POLICY_READ_EU,
		UserPermissions.ADA_API_POLICY_WRITE_NA,
		UserPermissions.ADA_API_POLICY_WRITE_EU,
		UserPermissions.ADA_API_POLICY_ASSIGNMENT_NA,
		UserPermissions.ADA_API_POLICY_ASSIGNMENT_EU,
		UserPermissions.ADA_API_FEATURE_WRITE_NA,
		UserPermissions.ADA_API_FEATURE_WRITE_EU,
		UserPermissions.ADA_API_STREAM_READ_WRITE_NA,
		UserPermissions.ADA_API_STREAM_READ_WRITE_EU,
		UserPermissions.ADA_API_USER_READ_NA,
		UserPermissions.ADA_API_USER_READ_EU,
		UserPermissions.ADA_API_USER_WRITE_NA,
		UserPermissions.ADA_API_USER_WRITE_EU
	],
	/**
	 * Creator role
	 * Policy read, Policy write, Web access
	 */
	[Roles.CREATOR]: [
		UserPermissions.ADA_API_POLICY_READ_NA,
		UserPermissions.ADA_API_POLICY_READ_EU,
		UserPermissions.ADA_API_POLICY_WRITE_NA,
		UserPermissions.ADA_API_POLICY_WRITE_EU,
		UserPermissions.ADA_API_WEB_ACCESS_NA,
		UserPermissions.ADA_API_WEB_ACCESS_EU
	],
	/**
	 * Viewer role
	 * Policy read, Vin profile read, Web access
	 */
	[Roles.VIEWER]: [
		UserPermissions.ADA_API_VIN_PROFILE_READ_NA,
		UserPermissions.ADA_API_VIN_PROFILE_READ_EU,
		UserPermissions.ADA_API_POLICY_READ_NA,
		UserPermissions.ADA_API_POLICY_READ_EU,
		UserPermissions.ADA_API_WEB_ACCESS_NA,
		UserPermissions.ADA_API_WEB_ACCESS_EU
	],
	/**
	 * Feature namer role
	 * Feature write
	 */
	[Roles.FEATURE_NAMER]: [
		UserPermissions.ADA_API_FEATURE_WRITE_NA,
		UserPermissions.ADA_API_FEATURE_WRITE_EU
	],
	/**
	 * Vin manager role
	 * Feature write, Policy assignment, vin profile read, web access
	 */
	[Roles.VIN_MANAGER]: [
		UserPermissions.ADA_API_FEATURE_WRITE_NA,
		UserPermissions.ADA_API_FEATURE_WRITE_EU,
		UserPermissions.ADA_API_POLICY_ASSIGNMENT_NA,
		UserPermissions.ADA_API_POLICY_ASSIGNMENT_EU,
		UserPermissions.ADA_API_VIN_PROFILE_READ_NA,
		UserPermissions.ADA_API_VIN_PROFILE_READ_EU,
		UserPermissions.ADA_API_WEB_ACCESS_NA,
		UserPermissions.ADA_API_WEB_ACCESS_EU
	]
};
