import React from "react";
import loadingLogo from "./LoadingLogo.png";
import "./LoadingLogo.scss";

/**
 * Name for this component.
 */
const COMPONENT_NAME = "LoadingLogo";

/**
 * A loading view to present to the user while a scene is loading.
 */
export const LoadingLogo: React.FC = () => {
	return (
		<div className={COMPONENT_NAME}>
			<img src={loadingLogo} className={`${COMPONENT_NAME}__image`} />
		</div>
	);
};
LoadingLogo.displayName = COMPONENT_NAME;
