import {
	Button,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { safeInvoke } from "../safeInvoke";

/**
 * The dialog instance type.
 */
export interface DialogInstance {
	/**
	 * The unique dialog ID.
	 */
	id: string;
	/**
	 * The content for this dialog.
	 */
	content: React.ReactNode;
}

/**
 * A set of utilities to hold a setState hook for dialog information statically.
 * This allows the global invocation of this setState function in other portions of the app.
 * This differs strictly from the context api as others needn't be injected with its props.
 *
 * NOTE: This very well might be anti-pattern. Let's take some time in the future to research
 * if the context api may actually be our preferred method for this use case.
 */
export class DialogFactory {
	/**
	 * Static instance of the dialog factory function.
	 */
	private static factoryFunction: React.Dispatch<
		React.SetStateAction<DialogInstance | undefined>
	>;

	/**
	 * Sets the factory function as callable from a static context.
	 * @param factoryFunction - the react setState hook that operates on dialog instance state;
	 */
	public static setFactoryFunction(
		factoryFunction: React.Dispatch<React.SetStateAction<DialogInstance | undefined>>
	): void {
		this.factoryFunction = factoryFunction;
	}

	/**
	 * Get the factory function.
	 * @returns the static factory function.
	 */
	public static getFactoryFunction(): React.Dispatch<
		React.SetStateAction<DialogInstance | undefined>
	> {
		return this.factoryFunction;
	}

	/**
	 * Invokes the static factory function.
	 * @param params - The factory function params. Pass undefined to close the dialog.
	 */
	public static invokeFactoryFunction(params: DialogInstance | undefined): void {
		this.factoryFunction(params);
	}
}

/**
 * The parameters for the ADA alert function.
 */
interface AdaAlertParams {
	/**
	 * The dialog title.
	 */
	title: string;
	/**
	 * The dialog body content.
	 */
	body: React.ReactNode;
	/**
	 * Optional handler for the close function.
	 */
	onClose?(): void;
}

/**
 * The content of an "alert" dialog.
 * @param props - user parameters and the unique dialog ID.
 * @returns the alert dialog.
 */
function AlertDialog(
	props: AdaAlertParams & {
		id: string;
	}
): JSX.Element {
	/**
	 * Handles close events.
	 */
	const handleClose = () => {
		safeInvoke(props.onClose);
		DialogFactory.invokeFactoryFunction(undefined);
	};

	return (
		<>
			<DialogTitle id={`${props.id}-dialog-title`}>{props.title}</DialogTitle>
			<DialogContent>
				<DialogContentText id={`${props.id}-dialog-description`}>
					{props.body}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					Ok
				</Button>
			</DialogActions>
		</>
	);
}

/**
 * Displays an alert dialog to the user.
 * @param params - The {@link AdaAlertParams}
 */
export function adaAlert(params: AdaAlertParams): void {
	const id = _.uniqueId();
	DialogFactory.invokeFactoryFunction({
		id: id,
		content: <AlertDialog id={id} {...params} />
	});
}

/**
 * The parameters for the ADA confirm function.
 */
interface AdaConfirmParams {
	/**
	 * The dialog title.
	 */
	title: string;
	/**
	 * The dialog body text.
	 */
	body: React.ReactNode;
	/**
	 * Called when the user confirms.
	 */
	onConfirm(): void;
	/**
	 * Optional handler for when the user closes the dialog.
	 */
	onClose?(): void;
	/**
	 * Override to the confirmation text.
	 */
	confirmText?: string;
}

/**
 * The confirmation dialog details.
 * @param props - user parameters and the unique dialog ID.
 * @returns the confirmation dialog.
 */
function ConfirmDialog(
	props: AdaConfirmParams & {
		id: string;
	}
): JSX.Element {
	useHotkeys("enter", () => {
		handleConfirm();
	});

	/**
	 * Handles close events.
	 * This function invokes a user supplied close event and then
	 * makes a call to the factory function to close the dialog.
	 */
	const handleClose = () => {
		safeInvoke(props.onClose);
		DialogFactory.invokeFactoryFunction(undefined);
	};

	/**
	 * Handles confirm event.
	 * This function invokes a user supplied confirmation event and then
	 * makes a call to the factory function to close the dialog.
	 */
	const handleConfirm = () => {
		props.onConfirm();
		DialogFactory.invokeFactoryFunction(undefined);
	};

	return (
		<>
			<DialogTitle id={`${props.id}-dialog-title`}>{props.title}</DialogTitle>
			<DialogContent>
				<DialogContentText id={`${props.id}-dialog-description`}>
					{props.body}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="inherit">
					Cancel
				</Button>
				<Button onClick={handleConfirm} color="primary" autoFocus>
					{props.confirmText || "Confirm"}
				</Button>
			</DialogActions>
		</>
	);
}

/**
 * Displays a confirmation dialog to the user.
 * @param params - The {@link AdaConfirmParams}
 */
export function adaConfirm(params: AdaConfirmParams): void {
	const id = _.uniqueId();
	DialogFactory.invokeFactoryFunction({
		id: id,
		content: <ConfirmDialog id={id} {...params} />
	});
}
