import { styled } from "@mui/material";
import JeepLogo1 from "./jeep-1.png";
import JeepLogo2 from "./jeep-2.png";

export const Logo = styled("img")(({ theme }) => ({
	position: "fixed",
	bottom: "0",
	animation: "move-image 10s linear",
	"@keyframes move-image": {
		"from": {
			"left": "150px"
		},
		"to": {
			"left": "calc(100% + 100px)"
		}
	},
	content: theme.palette.mode === "dark" ? `url(${JeepLogo2})` : `url(${JeepLogo1})`
}));
