import jwt_decode, { JwtPayload } from "jwt-decode";
import {
	IS_DEV_ENVIRONMENT,
	IS_LOCAL_DEV_ENVIRONMENT,
	IS_PRODUCTION_ENVIRONMENT,
	IS_STAGE_ENVIRONMENT,
	REFRESH_TOKEN_EXPIRATION_TIME
} from "../constants";

export const ACCESS_TOKEN = "ACCESS_TOKEN";

export const REFRESH_TOKEN = "REFRESH_TOKEN";

/**
 * /////////////////////////////
 * This file is used for both Batch UI interactions and ADA web usage for user authentication.
 * The Batch UI is a separate application that is used to provide batch/operations related functionality.
 * The Batch UI is hosted on a separate subdomain from the main application.
 * /////////////////////////////
 */

/**
 *  Utility to get environment-specific suffix
 */
function getEnvSuffix(): string {
	return IS_LOCAL_DEV_ENVIRONMENT
		? "-local"
		: IS_DEV_ENVIRONMENT
		? "-dev"
		: IS_STAGE_ENVIRONMENT
		? "-stage"
		: IS_PRODUCTION_ENVIRONMENT
		? "-prod"
		: "";
}

/**
 * Utility to get domain based on the environment
 */
function getDomain(): string {
	return IS_LOCAL_DEV_ENVIRONMENT
		? "localhost"
		: IS_DEV_ENVIRONMENT
		? ".dev.alldataapp.com"
		: IS_STAGE_ENVIRONMENT
		? ".stg.alldataapp.com"
		: IS_PRODUCTION_ENVIRONMENT
		? ".alldataapp.com"
		: "";
}

/**
 *  Construct the base cookie string
 */
function createBaseCookieString(
	name: string,
	value: string,
	path: string,
	maxAgeSeconds: number,
	domain: string
): string {
	return `${encodeURIComponent(name)}=${encodeURIComponent(
		value
	)}; Max-Age=${maxAgeSeconds}; Domain=${domain}; Path=${path}; Secure; SameSite=Lax;`;
}

/**
 * Sets an access cookie with the specified name, value, and path, applying environment-specific configurations.
 *
 * @param name - The base name of the cookie.
 * @param value - The value of the cookie (JWT).
 * @param path - The path scope of the cookie.
 */
export function setAuthCookie(name: string, value: string, path: string): void {
	const suffix = getEnvSuffix();
	const domain = getDomain();
	const modifiedName = name + suffix;

	// Parse JWT to get the expiration time
	const decodedJwt: JwtPayload = jwt_decode(value);
	let maxAgeSeconds = 0;
	if (decodedJwt && decodedJwt.exp) {
		const currentTimeInSeconds = Math.floor(Date.now() / 1000);
		maxAgeSeconds = Math.max(0, decodedJwt.exp - currentTimeInSeconds);
	}
	const cookieString = createBaseCookieString(modifiedName, value, path, maxAgeSeconds, domain);
	document.cookie = cookieString;
}

/**
 * Sets a refresh cookie with the specified name, value, and path, applying environment-specific configurations.
 *
 * @param name - The base name of the cookie.
 * @param value - The value of the cookie (JWT).
 * @param path - The path scope of the cookie.
 */
export function setRefreshCookie(name: string, value: string, path: string): void {
	const suffix = getEnvSuffix();
	const domain = getDomain();
	const modifiedName = name + suffix;
	const maxAgeSeconds = REFRESH_TOKEN_EXPIRATION_TIME;
	const cookieString = createBaseCookieString(modifiedName, value, path, maxAgeSeconds, domain);
	document.cookie = cookieString;
}

/**
 * Retrieves a cookie value based on its name, adjusting for environment-specific naming.
 *
 * @param name - The base name of the cookie.
 * @returns The value of the cookie if found, otherwise null.
 */
export function getCookie(name: string): string | null {
	const suffix = getEnvSuffix();
	const envSpecificName = name + suffix;
	const encodedName = encodeURIComponent(envSpecificName);
	const cookieString = `; ${document.cookie}`;
	const parts = cookieString.split(`; ${encodedName}=`);
	if (parts.length === 2) {
		const cookieValue = parts.pop()?.split(";").shift();
		return cookieValue ? decodeURIComponent(cookieValue) : null;
	}
	return null;
}

/**
 * Clears all cookies related to the current environment by setting their expiration date to the past.
 * This method targets clearing cookies when logging out from Batch UI or ADA web.
 */
export function clearCookie(): void {
	const suffix = getEnvSuffix();
	const domain = getDomain();
	const cookieString = document.cookie;
	const cookies = cookieString.split(";");

	cookies.forEach((cookie) => {
		const trimmedCookie = cookie.trim();
		const equalIndex = trimmedCookie.indexOf("=");
		if (equalIndex > -1) {
			const cookieName = trimmedCookie.substring(0, equalIndex).trim();

			// Check if the cookie name ends with the current environment suffix
			if (cookieName.endsWith(suffix)) {
				const deletionString = `${encodeURIComponent(
					cookieName
				)}=; Expires=Thu, 01 Jan 1970 00:00:00 GMT; Domain=${domain}; Path=/; Secure; SameSite=Lax;`;
				document.cookie = deletionString;
			}
		}
	});
}
