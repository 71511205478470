import axios, { AxiosResponse } from "axios";
import _ from "lodash";
import { LabelValuePair } from "../types";

export const APPROVER_ROLE_ID = "&858037714011684876";

const discordProxyEndpoint = process.env.REACT_APP_WEB_DISCORD_PROXY_URL!;

/**
 * Sends a message to a discord channel via webhook.
 * @param title - clickable title of the message
 * @param fields - array of label/value pairs
 * @param url - url to link to
 * @returns response from the webhook
 */
export const sendMessageWebhook = (
	title: string,
	fields: Array<LabelValuePair<string>>,
	url: string
): Promise<void | AxiosResponse<any>> => {
	return axios
		.post(
			discordProxyEndpoint,
			{
				title: title,
				fields: fields,
				url: url,
			},
			{
				headers: {
					"Content-type": "application/json",
					Accept: "*/*"
				}
			}
		)
		.then()
		.catch((error) => {
			throw new Error(error);
		});
};

/**
 * Sends message to discord channel for Fname approval requests
 * @param title - clickable title of the message
 * @param fields - array of label/value pairs
 * @param url - url to link to
 * @returns response from the webhook
 */
export const sendFnameApprovalReqMsg = (
	title: string,
	fields: Array<LabelValuePair<string>>,
	url: string
): Promise<void | AxiosResponse<any>> => {
	return axios
		.post(
			`${discordProxyEndpoint}/discord/fname-approvals`,
			{
				title: title,
				fields: fields,
				url: url,
			},
			{
				headers: {
					"Content-type": "application/json",
					Accept: "*/*"
				}
			}
		)
		.then()
		.catch((error) => {
			throw new Error(error);
		});
};
