import React from "react";
import * as Icons from "@mui/icons-material";
import * as Styled from "./adaSwitchStyles";

/**
 * The props interface for this component.
 */
interface Props {
	/**
	 * Aria label for component on state.
	 */
	onAriaLabel: string;
	/**
	 * Aria label for component off state.
	 */
	offAriaLabel: string;
	/**
	 * Icon for switch thumb.
	 */
	icon: Icons.SvgIconComponent;
	/**
	 * State for switch component.
	 */
	isSwitchChecked: boolean;
	/**
	 * On change handler for switch.
	 */
	onChange(): void;
	/**
	 * Size option, for a slightly smaller switch
	 */
	isSmall?: boolean;
}

/**
 * Name for this component.
 */
const COMPONENT_NAME = "AdaSwitch";

/**
 * A custom styled switch with an icon on the thumb.
 * @param props - component props.
 * @returns the rendered ada switch component.
 */
export const AdaSwitch: React.FC<Props> = (props) => {
	const label = {
		inputProps: {
			"aria-label": props.isSwitchChecked ? props.onAriaLabel : props.offAriaLabel
		}
	};

	const icon = <props.icon fontSize={props.isSmall ? "medium" : "large"} />;

	return (
		<Styled.AdaSwitchElement
			disableRipple
			{...label}
			onChange={() => props.onChange()}
			checked={props.isSwitchChecked}
			checkedIcon={icon}
			icon={icon}
			smallVariant={props.isSmall}
		></Styled.AdaSwitchElement>
	);
};

AdaSwitch.displayName = COMPONENT_NAME;
