import React from "react";
import { UserFavorites } from "./favoritesUtils";
import { UserPoliciesResponse } from "./userPolicies";

/**
 * Name for this component.
 */
const COMPONENT_NAME = "FavoritesContext";

interface UserFavoritesContext extends UserFavorites, UserPoliciesResponse {
	/**
	 * The provider's set state handler
	 */
	setUserFavorites: React.Dispatch<React.SetStateAction<UserFavorites>>;
	/**
	 * A method to refresh the user policies from the user api
	 */
	fetchUserPolicies(): void;
}

export const FavoritesContext = React.createContext<UserFavoritesContext>({
	recentVins: [],
	pinnedVins: [],
	pinnedPolicies: [],
	createdPolicies: [],
	searchedPolicies: [],
	patchedPolicies: [],
	recentPolicies: [],
	isLoading: false,
	setUserFavorites: () => {
		/**
		 * The component's set state function
		 */
	},
	fetchUserPolicies: () => {
		/**
		 * The component will fetch policies from the user api
		 */
	}
});

FavoritesContext.displayName = COMPONENT_NAME;
