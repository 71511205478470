import { styled } from "@mui/material";
import { bottomNavHeight } from "../../themes";
import { persistentInfoPanelWidth, totalInfoPanelWidth } from "../InfoPanel/infoPanelStyles";

export const RoutedBody = styled("div")(({ theme }) => ({
	display: "flex",
	flex: 1,
	[theme.breakpoints.down("md")]: {
		maxHeight: `calc(100vh - ${bottomNavHeight})`,
		overflowY: "scroll"
	}
}));

export const RoutedBodyContent = styled("div", {
	shouldForwardProp: (prop: string) => prop !== "isInfoPanelOpen"
})<{ isInfoPanelOpen: boolean }>(({ isInfoPanelOpen, theme }) => ({
	flexGrow: 1,
	display: "flex",
	// Adjusts max width for persistent into panel
	maxWidth: `calc(100% - ${persistentInfoPanelWidth}rem)`,
	// Adjusts max width on large and up screens if info panel is open so content can be opened side by side
	[theme.breakpoints.up("md")]: {
		maxWidth: `calc(100% - ${
			isInfoPanelOpen ? totalInfoPanelWidth : persistentInfoPanelWidth
		}rem)`
	}
}));
