import { Lock, SentimentDissatisfiedOutlined } from "@mui/icons-material";
import { Grid, Typography, styled } from "@mui/material";
import React from "react";
import { renderCustomHead } from "../../renderCustomHead";
import StyledTextLink from "../StyledTextLink";

/**
 * Name for this component.
 */
const COMPONENT_NAME = "ErrorPage403";

/**
 * Props interface for this component.
 */
interface Props {
	/**
	 * The errorCode eg: 403/404.
	 */
	errorCode: string;
}

/**
 * Center items of a grid and adds margin to the top
 */
const CenteredGridItems = styled(Grid)({
	textAlign: "center",
	alignContent: "center",
	marginTop: "15%"
});

/**
 * Adds margin to the top of typography
 */
const TypographyWithMarginTop = styled(Typography)({
	marginTop: "1rem"
});

/**
 * A wrapper for the error page
 */
const ErrorPageWrapper = styled("div")({
	width: "100%"
});

/**
 * The error page to present to the user in case of any
 * error while trying to access a scene.
 */
export const ErrorPage: React.FC<Props> = (props) => {
	/**
	 * Renders content based on the provided error code.
	 *
	 * @param errorCode - The error code to determine the content rendering.
	 *                    Valid error codes are '403' and '404'.
	 */
	const renderContentsFor = (errorCode: string) => {
		if (errorCode === "403") {
			return (
				<>
					<Lock style={{ fontSize: "5em", color: "GrayText" }} />
					<Typography variant="h1">403</Typography>
					<TypographyWithMarginTop>
						Uh-oh! It appears you are not permitted to access this resource.
					</TypographyWithMarginTop>
				</>
			);
		} else if (errorCode === "404") {
			return (
				<>
					<SentimentDissatisfiedOutlined style={{ fontSize: "5em", color: "GrayText" }} />
					<Typography variant="h1">404</Typography>
					<TypographyWithMarginTop>
						Uh-oh! What you are looking for might have been moved or doesn't exist at
						all. <br />
						Contact an administrator if this is unexpected.
					</TypographyWithMarginTop>
				</>
			);
		}
	};
	return (
		<ErrorPageWrapper className={COMPONENT_NAME}>
			{props.errorCode === "403"
				? renderCustomHead(`403: Forbidden`)
				: renderCustomHead(`404: Page Not Found`)}
			<CenteredGridItems>
				{renderContentsFor(props.errorCode)}
				<TypographyWithMarginTop variant="body2">
					Here are some helpful links instead:
				</TypographyWithMarginTop>
				<TypographyWithMarginTop variant="body2">
					<StyledTextLink to={`/`} title="Click to view home page">
						{"Home"}
					</StyledTextLink>
				</TypographyWithMarginTop>
				<Typography variant="body2">
					<StyledTextLink to={`/vins`} title="Click to search a VIN">
						{"Search a VIN"}
					</StyledTextLink>
				</Typography>
				<Typography variant="body2">
					<StyledTextLink to={`/policies`} title="Click to search a policy">
						{"Search a Policy"}
					</StyledTextLink>
				</Typography>
			</CenteredGridItems>
		</ErrorPageWrapper>
	);
};
