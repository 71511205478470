import dayjs from "dayjs";
import { RequestManager, swallowRequestManagerError } from "../../RequestManager";
import { UserPolicyDetails } from "../../api/apiModelTypes";
import {
	getUserCreatedPolicies,
	getUserPatchedPolicies,
	getUserSearchedPolicies
} from "../../api/userManagementService";

export interface UserPoliciesResponse {
	/**
	 * An array of user's created policies
	 * Will be undefined if failed to fetch
	 */
	createdPolicies: Array<UserPolicyDetails>;
	/**
	 * An array of user's recently searched policies
	 * Will be undefined if failed to fetch
	 */
	searchedPolicies: Array<UserPolicyDetails>;
	/**
	 * An array of user's recently patched policies
	 * Will be undefined if failed to fetch
	 */
	patchedPolicies: Array<UserPolicyDetails>;
	/**
	 * An array of the 10 most recently accessed by the user (searched, created, or patched) policy ids
	 */
	recentPolicies: Array<string>;
	/**
	 * The loading state for the user's policies
	 */
	isLoading: boolean;
}

/**
 * Gets user's searched, created, and patched polices
 * @param requestManager the request manager for the component calling this function
 * @returns an object with created, searched, patched policies and 10 most recent policy ids
 */
export const loadUserPolicies = async (
	requestManager: RequestManager
): Promise<UserPoliciesResponse> => {
	const userActivity: UserPoliciesResponse = {
		createdPolicies: [],
		searchedPolicies: [],
		patchedPolicies: [],
		recentPolicies: [],
		isLoading: false
	};

	// Promise for the first API call (User Created Policies)
	const promiseCreatedPolicies = requestManager
		.send(getUserCreatedPolicies)
		.then((result: any) => {
			userActivity.createdPolicies = result.data;
		})
		.catch((error) => {
			swallowRequestManagerError(error);
		});

	// Promise for the second API call (User Searched Policies)
	const promiseSearchedPolicies = requestManager
		.send(getUserSearchedPolicies)
		.then((result: any) => {
			userActivity.searchedPolicies = result.data;
		})
		.catch((error) => {
			swallowRequestManagerError(error);
		});

	// Promise for the third API call (User Patched Policies)
	const promisePatchedPolicies = requestManager
		.send(getUserPatchedPolicies)
		.then((result: any) => {
			userActivity.patchedPolicies = result.data;
		})
		.catch((error) => {
			swallowRequestManagerError(error);
		});

	return Promise.all([
		promiseCreatedPolicies,
		promiseSearchedPolicies,
		promisePatchedPolicies
	]).then(() => {
		userActivity.recentPolicies = [
			...(userActivity.createdPolicies ?? []),
			...(userActivity.patchedPolicies ?? []),
			...(userActivity.searchedPolicies ?? [])
		]
			.sort((a, b) => {
				return dayjs(b.Timestamp).valueOf() - dayjs(a.Timestamp).valueOf();
			})
			.slice(0, 10)
			.map((policy) => String(policy.PolicyId));
		return userActivity;
	});
};
