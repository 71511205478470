import React from 'react';

/**
 * The two timezone options for a user to choose from
 */
export type Timezone = 'utc' | 'local';

/**
 * The user's preferred timezone
 */
export const TimezonePreferenceContext = React.createContext<Timezone | null>(null);
