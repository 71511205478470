import { Amplify, Analytics } from "aws-amplify";
import { IS_DEV_ENVIRONMENT, IS_LOCAL_DEV_ENVIRONMENT } from "../constants";

/**
 * Configures the AWS Amplify library for authentication and analytics.
 */
export const Configure = () => {
	Amplify.configure({
		Auth: {
			// Configure your AWS authentication settings if you are using Amplify Auth module
			identityPoolId: process.env.REACT_APP_ANALYTICS_IDENTITY_POOL_ID,
			region: process.env.REACT_APP_ANALYTICS_REGION
		},
		Analytics: {
			// OPTIONAL - Allow recording session events. Default is true.
			autoSessionRecord: false,
			AWSPinpoint: {
				// Configure your AWS credentials and Pinpoint project ID
				appId: process.env.REACT_APP_ANALYTICS_PINPOINT_ID,
				// Amazon service region
				region: process.env.REACT_APP_ANALYTICS_REGION,
				mandatorySignIn: false
			}
		}
	});
};

/**
 * Updates the user's endpoint with location and country information.
 */
export const updateEndpoint = async () => {
	try {
		const fetchedIpInfo = await fetch("https://ipapi.co/json/");
		const ipInfo = await fetchedIpInfo.json();
		const { country_code_iso3, city } = ipInfo;

		// Update the user's endpoint with country code
		await Analytics.updateEndpoint({
			location: {
				country: country_code_iso3 ? country_code_iso3 : "",
				city: city ? city : ""
			}
		});
	} catch (error) {
		console.error("Error fetching or updating endpoint:", error);
	}
};

/**
 * Records an event using AWS Amplify Analytics.
 * @param {string} eventName - The name of the event to record.
 * @param {any} key - Additional attributes or data associated with the event.
 */
export const recordEvent = async (eventName: string, key: any) => {
	if (IS_LOCAL_DEV_ENVIRONMENT || IS_DEV_ENVIRONMENT) {
		try {
			// Record the event
			await Analytics.record({
				name: eventName,
				attributes: { key }
			});
		} catch (error) {
			console.error("Error recording event:", error);
		}
	} else {
		// console.log("Event Recording is not being done in STAGE or PROD environments");
	}
};
