// NOTE: This is generated code and should NOT be edited for any reason.
// If an error occurs with the generated code, submit a PR into 
// https://github.com/MSDOStoevsky/tag-api-codegen or alter the 
// project locally if forked.
/* eslint-disable  @typescript-eslint/no-empty-interface */
/* eslint-disable  @typescript-eslint/no-unused-vars */
import * as ApiModelTypes from "../apiModelTypes";
import axios, { AxiosResponse, RawAxiosRequestHeaders } from "axios";

/**
 * The service base path.
 */
const BASE_PATH = `${process.env.REACT_APP_API_URL}`;


/**
 * The query parameters for the {@link refreshAuthToken} function.
 */
export interface refreshAuthTokenQueryParams {
}

/**
 * Exchange refresh token for new bearer token
 * @param params - The request parameters (or undefined).
 * @param payload - the request payload (or undefined).
 * @param headers - the request headers.
 * @param signal - optional AbortSignal to cancel this request.
 * @param queryParameters - optional request query parameters (or undefined).
 * @param basePathOverride - optional override to the base path.
 * @returns api request configuration data.
 */
export function refreshAuthToken(
	params: undefined,
	payload: Record<string, unknown>,
	headers: RawAxiosRequestHeaders,
	signal?: AbortSignal,
	queryParameters?: refreshAuthTokenQueryParams | undefined,
	basePathOverride?: string
): Promise<AxiosResponse<ApiModelTypes.RefreshTokenResponse>> {
	return axios.request<ApiModelTypes.RefreshTokenResponse>({
		headers: headers,
		baseURL: `${basePathOverride || BASE_PATH}`,
		url: `/v1/auth/token/refresh`,
		method: "post",
		params: queryParameters,
		data: payload,
		signal: signal
	});
}

/**
 * The query parameters for the {@link getGroups} function.
 */
export interface getGroupsQueryParams {
}

/**
 * Get all groups
 * @param params - The request parameters (or undefined).
 * @param payload - the request payload (or undefined).
 * @param headers - the request headers.
 * @param signal - optional AbortSignal to cancel this request.
 * @param queryParameters - optional request query parameters (or undefined).
 * @param basePathOverride - optional override to the base path.
 * @returns api request configuration data.
 */
export function getGroups(
	params: undefined,
	payload: undefined,
	headers: RawAxiosRequestHeaders,
	signal?: AbortSignal,
	queryParameters?: getGroupsQueryParams | undefined,
	basePathOverride?: string
): Promise<AxiosResponse<ApiModelTypes.GetGroupsResponse>> {
	return axios.request<ApiModelTypes.GetGroupsResponse>({
		headers: headers,
		baseURL: `${basePathOverride || BASE_PATH}`,
		url: `/v1/user/group`,
		method: "get",
		params: queryParameters,
		data: payload,
		signal: signal
	});
}

/**
 * The query parameters for the {@link createGroup} function.
 */
export interface createGroupQueryParams {
}

/**
 * Creates a new group
 * @param params - The request parameters (or undefined).
 * @param payload - the request payload (or undefined).
 * @param headers - the request headers.
 * @param signal - optional AbortSignal to cancel this request.
 * @param queryParameters - optional request query parameters (or undefined).
 * @param basePathOverride - optional override to the base path.
 * @returns api request configuration data.
 */
export function createGroup(
	params: undefined,
	payload: ApiModelTypes.CreateGroupRequest,
	headers: RawAxiosRequestHeaders,
	signal?: AbortSignal,
	queryParameters?: createGroupQueryParams | undefined,
	basePathOverride?: string
): Promise<AxiosResponse<ApiModelTypes.Role>> {
	return axios.request<ApiModelTypes.Role>({
		headers: headers,
		baseURL: `${basePathOverride || BASE_PATH}`,
		url: `/v1/user/group`,
		method: "post",
		params: queryParameters,
		data: payload,
		signal: signal
	});
}

/**
 * The query parameters for the {@link getIamPolicies} function.
 */
export interface getIamPoliciesQueryParams {
}

/**
 * Get all policies associated with ADA groups&#x2F;roles
 * @param params - The request parameters (or undefined).
 * @param payload - the request payload (or undefined).
 * @param headers - the request headers.
 * @param signal - optional AbortSignal to cancel this request.
 * @param queryParameters - optional request query parameters (or undefined).
 * @param basePathOverride - optional override to the base path.
 * @returns api request configuration data.
 */
export function getIamPolicies(
	params: undefined,
	payload: undefined,
	headers: RawAxiosRequestHeaders,
	signal?: AbortSignal,
	queryParameters?: getIamPoliciesQueryParams | undefined,
	basePathOverride?: string
): Promise<AxiosResponse<ApiModelTypes.GetIamPoliciesReponse>> {
	return axios.request<ApiModelTypes.GetIamPoliciesReponse>({
		headers: headers,
		baseURL: `${basePathOverride || BASE_PATH}`,
		url: `/v1/user/group/iam-policy`,
		method: "get",
		params: queryParameters,
		data: payload,
		signal: signal
	});
}
/**
 * The path parameters for the {@link deleteUserFromGroup} function.
 */
export interface deleteUserFromGroupParams {
	/**
	 * stub
	 */
	groupId: string;
	/**
	 * stub
	 */
	userId: string;
}

/**
 * The query parameters for the {@link deleteUserFromGroup} function.
 */
export interface deleteUserFromGroupQueryParams {
}

/**
 * Remove user from a group
 * @param params - The request parameters (or undefined).
 * @param payload - the request payload (or undefined).
 * @param headers - the request headers.
 * @param signal - optional AbortSignal to cancel this request.
 * @param queryParameters - optional request query parameters (or undefined).
 * @param basePathOverride - optional override to the base path.
 * @returns api request configuration data.
 */
export function deleteUserFromGroup(
	params: deleteUserFromGroupParams,
	payload: undefined,
	headers: RawAxiosRequestHeaders,
	signal?: AbortSignal,
	queryParameters?: deleteUserFromGroupQueryParams | undefined,
	basePathOverride?: string
): Promise<AxiosResponse<Record<string, unknown>>> {
	return axios.request<Record<string, unknown>>({
		headers: headers,
		baseURL: `${basePathOverride || BASE_PATH}`,
		url: `/v1/user/group/${params.groupId}/${params.userId}`,
		method: "delete",
		params: queryParameters,
		data: payload,
		signal: signal
	});
}
/**
 * The path parameters for the {@link searchGroupUsers} function.
 */
export interface searchGroupUsersParams {
	/**
	 * stub
	 */
	groupId: string;
}

/**
 * The query parameters for the {@link searchGroupUsers} function.
 */
export interface searchGroupUsersQueryParams {
}

/**
 * Search users that belong to this group
 * @param params - The request parameters (or undefined).
 * @param payload - the request payload (or undefined).
 * @param headers - the request headers.
 * @param signal - optional AbortSignal to cancel this request.
 * @param queryParameters - optional request query parameters (or undefined).
 * @param basePathOverride - optional override to the base path.
 * @returns api request configuration data.
 */
export function searchGroupUsers(
	params: searchGroupUsersParams,
	payload: ApiModelTypes.SearchRequest,
	headers: RawAxiosRequestHeaders,
	signal?: AbortSignal,
	queryParameters?: searchGroupUsersQueryParams | undefined,
	basePathOverride?: string
): Promise<AxiosResponse<ApiModelTypes.UserSearchResponse>> {
	return axios.request<ApiModelTypes.UserSearchResponse>({
		headers: headers,
		baseURL: `${basePathOverride || BASE_PATH}`,
		url: `/v1/user/group/${params.groupId}/search`,
		method: "post",
		params: queryParameters,
		data: payload,
		signal: signal
	});
}
/**
 * The path parameters for the {@link getGroup} function.
 */
export interface getGroupParams {
	/**
	 * stub
	 */
	groupId: string;
}

/**
 * The query parameters for the {@link getGroup} function.
 */
export interface getGroupQueryParams {
}

/**
 * Get a single group
 * @param params - The request parameters (or undefined).
 * @param payload - the request payload (or undefined).
 * @param headers - the request headers.
 * @param signal - optional AbortSignal to cancel this request.
 * @param queryParameters - optional request query parameters (or undefined).
 * @param basePathOverride - optional override to the base path.
 * @returns api request configuration data.
 */
export function getGroup(
	params: getGroupParams,
	payload: undefined,
	headers: RawAxiosRequestHeaders,
	signal?: AbortSignal,
	queryParameters?: getGroupQueryParams | undefined,
	basePathOverride?: string
): Promise<AxiosResponse<ApiModelTypes.Group>> {
	return axios.request<ApiModelTypes.Group>({
		headers: headers,
		baseURL: `${basePathOverride || BASE_PATH}`,
		url: `/v1/user/group/${params.groupId}`,
		method: "get",
		params: queryParameters,
		data: payload,
		signal: signal
	});
}
/**
 * The path parameters for the {@link addUserToGroup} function.
 */
export interface addUserToGroupParams {
	/**
	 * stub
	 */
	groupId: string;
}

/**
 * The query parameters for the {@link addUserToGroup} function.
 */
export interface addUserToGroupQueryParams {
}

/**
 * Add a user to a group
 * @param params - The request parameters (or undefined).
 * @param payload - the request payload (or undefined).
 * @param headers - the request headers.
 * @param signal - optional AbortSignal to cancel this request.
 * @param queryParameters - optional request query parameters (or undefined).
 * @param basePathOverride - optional override to the base path.
 * @returns api request configuration data.
 */
export function addUserToGroup(
	params: addUserToGroupParams,
	payload: Record<string, unknown>,
	headers: RawAxiosRequestHeaders,
	signal?: AbortSignal,
	queryParameters?: addUserToGroupQueryParams | undefined,
	basePathOverride?: string
): Promise<AxiosResponse<Record<string, unknown>>> {
	return axios.request<Record<string, unknown>>({
		headers: headers,
		baseURL: `${basePathOverride || BASE_PATH}`,
		url: `/v1/user/group/${params.groupId}`,
		method: "post",
		params: queryParameters,
		data: payload,
		signal: signal
	});
}

/**
 * The query parameters for the {@link searchUsers} function.
 */
export interface searchUsersQueryParams {
}

/**
 * Search users
 * @param params - The request parameters (or undefined).
 * @param payload - the request payload (or undefined).
 * @param headers - the request headers.
 * @param signal - optional AbortSignal to cancel this request.
 * @param queryParameters - optional request query parameters (or undefined).
 * @param basePathOverride - optional override to the base path.
 * @returns api request configuration data.
 */
export function searchUsers(
	params: undefined,
	payload: ApiModelTypes.SearchRequest,
	headers: RawAxiosRequestHeaders,
	signal?: AbortSignal,
	queryParameters?: searchUsersQueryParams | undefined,
	basePathOverride?: string
): Promise<AxiosResponse<ApiModelTypes.UserSearchResponse>> {
	return axios.request<ApiModelTypes.UserSearchResponse>({
		headers: headers,
		baseURL: `${basePathOverride || BASE_PATH}`,
		url: `/v1/user/search`,
		method: "post",
		params: queryParameters,
		data: payload,
		signal: signal
	});
}

/**
 * The query parameters for the {@link getMe} function.
 */
export interface getMeQueryParams {
}

/**
 * Get the user data of the calling user
 * @param params - The request parameters (or undefined).
 * @param payload - the request payload (or undefined).
 * @param headers - the request headers.
 * @param signal - optional AbortSignal to cancel this request.
 * @param queryParameters - optional request query parameters (or undefined).
 * @param basePathOverride - optional override to the base path.
 * @returns api request configuration data.
 */
export function getMe(
	params: undefined,
	payload: undefined,
	headers: RawAxiosRequestHeaders,
	signal?: AbortSignal,
	queryParameters?: getMeQueryParams | undefined,
	basePathOverride?: string
): Promise<AxiosResponse<ApiModelTypes.Me>> {
	return axios.request<ApiModelTypes.Me>({
		headers: headers,
		baseURL: `${basePathOverride || BASE_PATH}`,
		url: `/v1/user/me`,
		method: "get",
		params: queryParameters,
		data: payload,
		signal: signal
	});
}

/**
 * The query parameters for the {@link createUser} function.
 */
export interface createUserQueryParams {
}

/**
 * Create a single user
 * @param params - The request parameters (or undefined).
 * @param payload - the request payload (or undefined).
 * @param headers - the request headers.
 * @param signal - optional AbortSignal to cancel this request.
 * @param queryParameters - optional request query parameters (or undefined).
 * @param basePathOverride - optional override to the base path.
 * @returns api request configuration data.
 */
export function createUser(
	params: undefined,
	payload: ApiModelTypes.CreateUserRequest,
	headers: RawAxiosRequestHeaders,
	signal?: AbortSignal,
	queryParameters?: createUserQueryParams | undefined,
	basePathOverride?: string
): Promise<AxiosResponse<ApiModelTypes.User>> {
	return axios.request<ApiModelTypes.User>({
		headers: headers,
		baseURL: `${basePathOverride || BASE_PATH}`,
		url: `/v1/user`,
		method: "post",
		params: queryParameters,
		data: payload,
		signal: signal
	});
}
/**
 * The path parameters for the {@link getUser} function.
 */
export interface getUserParams {
	/**
	 * stub
	 */
	userId: string;
}

/**
 * The query parameters for the {@link getUser} function.
 */
export interface getUserQueryParams {
}

/**
 * Get a single user
 * @param params - The request parameters (or undefined).
 * @param payload - the request payload (or undefined).
 * @param headers - the request headers.
 * @param signal - optional AbortSignal to cancel this request.
 * @param queryParameters - optional request query parameters (or undefined).
 * @param basePathOverride - optional override to the base path.
 * @returns api request configuration data.
 */
export function getUser(
	params: getUserParams,
	payload: undefined,
	headers: RawAxiosRequestHeaders,
	signal?: AbortSignal,
	queryParameters?: getUserQueryParams | undefined,
	basePathOverride?: string
): Promise<AxiosResponse<ApiModelTypes.User>> {
	return axios.request<ApiModelTypes.User>({
		headers: headers,
		baseURL: `${basePathOverride || BASE_PATH}`,
		url: `/v1/user/${params.userId}`,
		method: "get",
		params: queryParameters,
		data: payload,
		signal: signal
	});
}
/**
 * The path parameters for the {@link getUserGroups} function.
 */
export interface getUserGroupsParams {
	/**
	 * stub
	 */
	userId: string;
}

/**
 * The query parameters for the {@link getUserGroups} function.
 */
export interface getUserGroupsQueryParams {
}

/**
 * Get all groups that a user belongs to
 * @param params - The request parameters (or undefined).
 * @param payload - the request payload (or undefined).
 * @param headers - the request headers.
 * @param signal - optional AbortSignal to cancel this request.
 * @param queryParameters - optional request query parameters (or undefined).
 * @param basePathOverride - optional override to the base path.
 * @returns api request configuration data.
 */
export function getUserGroups(
	params: getUserGroupsParams,
	payload: undefined,
	headers: RawAxiosRequestHeaders,
	signal?: AbortSignal,
	queryParameters?: getUserGroupsQueryParams | undefined,
	basePathOverride?: string
): Promise<AxiosResponse<ApiModelTypes.GetGroupsResponse>> {
	return axios.request<ApiModelTypes.GetGroupsResponse>({
		headers: headers,
		baseURL: `${basePathOverride || BASE_PATH}`,
		url: `/v1/user/${params.userId}/group`,
		method: "get",
		params: queryParameters,
		data: payload,
		signal: signal
	});
}

/**
 * The query parameters for the {@link emailUser} function.
 */
export interface emailUserQueryParams {
}

/**
 * Email a single user that their permissions have changed
 * @param params - The request parameters (or undefined).
 * @param payload - the request payload (or undefined).
 * @param headers - the request headers.
 * @param signal - optional AbortSignal to cancel this request.
 * @param queryParameters - optional request query parameters (or undefined).
 * @param basePathOverride - optional override to the base path.
 * @returns api request configuration data.
 */
export function emailUser(
	params: undefined,
	payload: Record<string, unknown>,
	headers: RawAxiosRequestHeaders,
	signal?: AbortSignal,
	queryParameters?: emailUserQueryParams | undefined,
	basePathOverride?: string
): Promise<AxiosResponse<Record<string, unknown>>> {
	return axios.request<Record<string, unknown>>({
		headers: headers,
		baseURL: `${basePathOverride || BASE_PATH}`,
		url: `/v1/user/email`,
		method: "post",
		params: queryParameters,
		data: payload,
		signal: signal
	});
}

/**
 * The query parameters for the {@link getUserCreatedPolicies} function.
 */
export interface getUserCreatedPoliciesQueryParams {
}

/**
 * get user created policy details from auroraDB
 * @param params - The request parameters (or undefined).
 * @param payload - the request payload (or undefined).
 * @param headers - the request headers.
 * @param signal - optional AbortSignal to cancel this request.
 * @param queryParameters - optional request query parameters (or undefined).
 * @param basePathOverride - optional override to the base path.
 * @returns api request configuration data.
 */
export function getUserCreatedPolicies(
	params: undefined,
	payload: undefined,
	headers: RawAxiosRequestHeaders,
	signal?: AbortSignal,
	queryParameters?: getUserCreatedPoliciesQueryParams | undefined,
	basePathOverride?: string
): Promise<AxiosResponse<ApiModelTypes.GetUserPoliciesResponse>> {
	return axios.request<ApiModelTypes.GetUserPoliciesResponse>({
		headers: headers,
		baseURL: `${basePathOverride || BASE_PATH}`,
		url: `/v1/user/profile/policies/created`,
		method: "get",
		params: queryParameters,
		data: payload,
		signal: signal
	});
}

/**
 * The query parameters for the {@link getUserSearchedPolicies} function.
 */
export interface getUserSearchedPoliciesQueryParams {
}

/**
 * get user searched policy details from auroraDB
 * @param params - The request parameters (or undefined).
 * @param payload - the request payload (or undefined).
 * @param headers - the request headers.
 * @param signal - optional AbortSignal to cancel this request.
 * @param queryParameters - optional request query parameters (or undefined).
 * @param basePathOverride - optional override to the base path.
 * @returns api request configuration data.
 */
export function getUserSearchedPolicies(
	params: undefined,
	payload: undefined,
	headers: RawAxiosRequestHeaders,
	signal?: AbortSignal,
	queryParameters?: getUserSearchedPoliciesQueryParams | undefined,
	basePathOverride?: string
): Promise<AxiosResponse<ApiModelTypes.GetUserPoliciesResponse>> {
	return axios.request<ApiModelTypes.GetUserPoliciesResponse>({
		headers: headers,
		baseURL: `${basePathOverride || BASE_PATH}`,
		url: `/v1/user/profile/policies/searched`,
		method: "get",
		params: queryParameters,
		data: payload,
		signal: signal
	});
}

/**
 * The query parameters for the {@link getUserPatchedPolicies} function.
 */
export interface getUserPatchedPoliciesQueryParams {
}

/**
 * get user patched policy details from auroraDB
 * @param params - The request parameters (or undefined).
 * @param payload - the request payload (or undefined).
 * @param headers - the request headers.
 * @param signal - optional AbortSignal to cancel this request.
 * @param queryParameters - optional request query parameters (or undefined).
 * @param basePathOverride - optional override to the base path.
 * @returns api request configuration data.
 */
export function getUserPatchedPolicies(
	params: undefined,
	payload: undefined,
	headers: RawAxiosRequestHeaders,
	signal?: AbortSignal,
	queryParameters?: getUserPatchedPoliciesQueryParams | undefined,
	basePathOverride?: string
): Promise<AxiosResponse<ApiModelTypes.GetUserPoliciesResponse>> {
	return axios.request<ApiModelTypes.GetUserPoliciesResponse>({
		headers: headers,
		baseURL: `${basePathOverride || BASE_PATH}`,
		url: `/v1/user/profile/policies/patched`,
		method: "get",
		params: queryParameters,
		data: payload,
		signal: signal
	});
}

/**
 * The query parameters for the {@link getUserDetails} function.
 */
export interface getUserDetailsQueryParams {
}

/**
 * get details of a user from auroraDB.
 * @param params - The request parameters (or undefined).
 * @param payload - the request payload (or undefined).
 * @param headers - the request headers.
 * @param signal - optional AbortSignal to cancel this request.
 * @param queryParameters - optional request query parameters (or undefined).
 * @param basePathOverride - optional override to the base path.
 * @returns api request configuration data.
 */
export function getUserDetails(
	params: undefined,
	payload: undefined,
	headers: RawAxiosRequestHeaders,
	signal?: AbortSignal,
	queryParameters?: getUserDetailsQueryParams | undefined,
	basePathOverride?: string
): Promise<AxiosResponse<ApiModelTypes.GetUserDetailsResponse>> {
	return axios.request<ApiModelTypes.GetUserDetailsResponse>({
		headers: headers,
		baseURL: `${basePathOverride || BASE_PATH}`,
		url: `/v1/user/profile/details`,
		method: "get",
		params: queryParameters,
		data: payload,
		signal: signal
	});
}

/**
 * The query parameters for the {@link updateUserDetails} function.
 */
export interface updateUserDetailsQueryParams {
}

/**
 * edit theme&#x2F;region or both for a user in auroraDB.
 * @param params - The request parameters (or undefined).
 * @param payload - the request payload (or undefined).
 * @param headers - the request headers.
 * @param signal - optional AbortSignal to cancel this request.
 * @param queryParameters - optional request query parameters (or undefined).
 * @param basePathOverride - optional override to the base path.
 * @returns api request configuration data.
 */
export function updateUserDetails(
	params: undefined,
	payload: ApiModelTypes.UpdateUserDetailsRequest,
	headers: RawAxiosRequestHeaders,
	signal?: AbortSignal,
	queryParameters?: updateUserDetailsQueryParams | undefined,
	basePathOverride?: string
): Promise<AxiosResponse<ApiModelTypes.UpdateUserDetailsResponse>> {
	return axios.request<ApiModelTypes.UpdateUserDetailsResponse>({
		headers: headers,
		baseURL: `${basePathOverride || BASE_PATH}`,
		url: `/v1/user/profile/details`,
		method: "patch",
		params: queryParameters,
		data: payload,
		signal: signal
	});
}
