import _ from "lodash";
import React from "react";
import * as ApiModelTypes from "../../api/apiModelTypes";
import { SCENES, SCENE_PERMISSION_MAP } from "../RoutedBody/scenesUtil";
import "./UserPermissionGuard.scss";

/**
 * Name for this component.
 */
export const COMPONENT_NAME = "UserPermissionGuard";

/**
 * Props interface for this component.
 */
interface Props {
	/**
	 * The user.
	 */
	user: ApiModelTypes.Me;
	/**
	 * The scene to guard.
	 */
	scene: SCENES;
	/**
	 * The error fallback page.
	 */
	errorPage: React.ReactNode;
	/**
	 * The component children.
	 */
	children(): React.ReactNode;
}

/**
 * A component which wraps another in order to prevent it from being rendered
 * if the user does not have the proper permissions.
 * @param props - component props.
 */
export const UserPermissionGuard: React.FC<Props> = (props) => {
	const isPermissibleByOperation = _.some(props.user.operations, (operation) => {
		return _.includes(SCENE_PERMISSION_MAP[operation], props.scene);
	});

	return (
		<div className={COMPONENT_NAME}>
			{isPermissibleByOperation ? props.children() : props.errorPage}
		</div>
	);
};
UserPermissionGuard.displayName = COMPONENT_NAME;
