import AccessTimeIcon from "@mui/icons-material/AccessTime";
import React, { useEffect, useState } from "react";

interface Props {
	timezonePreference: "local" | "utc";
	onToggleTime: () => void;
}

/**
 * Name for this component.
 */
const COMPONENT_NAME = "Time";

/**
 * Clock component to display time and toggle time zone.
 * @param {Object} props - Component props.
 * @returns {JSX.Element} Clock component.
 */
export const Time: React.FC<Props> = (props) => {
	const [currentTime, setCurrentTime] = useState(new Date());
	const [timezoneOffset, setTimezoneOffset] = useState<number>(0); // Initialize offset to 0 for local time

	useEffect(() => {
		const intervalID = setInterval(() => {
			// Updates current time every second
			setCurrentTime(new Date());
		}, 1000);

		return () => clearInterval(intervalID);
	}, []);

	useEffect(() => {
		setTimezoneOffset(
			props.timezonePreference === "utc" ? new Date().getTimezoneOffset() * 60000 : 0
		);
	}, [props.timezonePreference]);

	/**
	 * Format the given date into a time string.
	 * @param {Date} date - The date to format.
	 * @param {Intl.DateTimeFormatOptions} options - The formatting options.
	 * @returns {string} Formatted time string.
	 */
	const formatTime = (date: Date) => {
		const options: Intl.DateTimeFormatOptions = {
			hour: "numeric",
			minute: "numeric",
			second: "numeric",
			hour12: true
		};
		return date.toLocaleTimeString(undefined, options);
	};

	const displayTime = formatTime(new Date(currentTime.getTime() + timezoneOffset));

	return (
		<div style={{ display: "flex", alignItems: "center" }} onClick={props.onToggleTime}>
			<AccessTimeIcon style={{ marginRight: "8px" }} />
			<span>
				{displayTime}{" "}
				{timezoneOffset === 0 ? Intl.DateTimeFormat().resolvedOptions().timeZone : "UTC"}
			</span>
		</div>
	);
};

Time.displayName = COMPONENT_NAME;
