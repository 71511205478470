/**
 * Utility to call a potentially undefined function.
 * @param fn - the provided function or "undefined".
 * @param params - the parameters to supply to `fn`.
 */
export function safeInvoke<T extends any[]>(
	fn: ((...params: T) => void) | undefined,
	...params: T
): void {
	if (!fn) {
		return;
	} else {
		fn(...params);
	}
}
