import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import { DialogTransition } from "../Dialog/DialogTransition";
import "./ErrorDialog.scss";

/**
 * Name for this component.
 */
const COMPONENT_NAME = "ErrorDialog";

/**
 * Props interface for this component.
 */
interface Props {
	/**
	 * The message to display to the user.
	 */
	message: string | undefined;
	/**
	 * Flag controlling the open state of this dialog.
	 */
	isOpen: boolean;
	/**
	 * Called when this dialog is to close.
	 */
	onClose(): void;
}

/**
 * A general error dialog.
 * @return A general error dialog.
 */
export const ErrorDialog: React.FC<Props> = (props) => {
	return (
		<Dialog
			className={COMPONENT_NAME}
			open={props.isOpen}
			onClose={() => {
				props.onClose();
			}}
			PaperProps={{
				className: `${COMPONENT_NAME}__paper`
			}}
			TransitionComponent={DialogTransition}
		>
			<DialogTitle>Whoops! An Error Occured</DialogTitle>
			<DialogContent>{props.message}</DialogContent>
			<DialogActions>
				<Button
					color={"inherit"}
					onClick={() => {
						props.onClose();
					}}
				>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};
ErrorDialog.displayName = COMPONENT_NAME;
