import React from "react";
import { DefaultInfoPanelConfig, InfoPanelConfig } from "./infoPanelConfig";

export interface InfoPanelContextType extends InfoPanelConfig {
	/**
	 * Sets panel config
	 * A scene with its own config calls this to set the panel
	 */
	setInfoConfig: (sceneInfoPanelConfig: InfoPanelConfig) => void;
	/**
	 * Resets panel config with default info
	 * A scene with its own config calls this to reset the panel
	 */
	resetInfoConfig: () => void;
	/**
	 * The currently open section
	 */
	openSection: string | undefined;
	/**
	 * Sets the state of the open panel
	 */
	setOpenSection: (titleToOpen: string) => void;
	/**
	 * Handles manually opening panel
	 */
	setOpenState: (newOpenState: boolean) => void;
}

/**
 * Name for this component.
 */
const COMPONENT_NAME = "InfoPanelContext";

/**
 * @returns a boolean if the user belongs to an Area 51 group (starts with "source_")
 */
export const InfoPanelContext = React.createContext<InfoPanelContextType>({
	...DefaultInfoPanelConfig,
	openSection: undefined,
	setInfoConfig: () => {
		/**
		 * Sets panel config
		 */
	},
	resetInfoConfig: () => {
		/**
		 * Resets panel config with default info
		 */
	},
	setOpenState: () => {
		/**
		 * Opens the info panel
		 */
	},
	setOpenSection: () => {
		/**
		 * Sets the open section
		 */
	}
});

InfoPanelContext.displayName = COMPONENT_NAME;
