import _ from "lodash";
import { $enum } from "ts-enum-util";
import * as ApiModelTypes from "../../api/apiModelTypes";

/**
 * Supported regions
 */
export enum Regions {
	NA = "NA",
	EU = "EU"
}

/**
 * Grabs the appropriate API url depending on what region is active for the user
 * inside our app
 */
export const REGION_BASE_URL_MAP: Record<Regions, string | undefined> = {
	EU: process.env.REACT_APP_EMEA_API_URL,
	// This is not technically correct, but is being used in a hacky way
	// to prevent the request manager from overriding the service file
	// base URL (for other apis with uncommon base URLs that are region agnostic)
	NA: undefined
};

/**
 * Serves as a way to combine our NA and EU region permissions into a single region agnostic type
 */
type RegionAgnosticPermission<S extends string> = S extends `${infer PermissionName}_${any}`
	? PermissionName
	: "Cannot parse permission string";

/**
 * Get available user permissions
 */
export const PERMISSIONS_BY_REGION = _($enum(ApiModelTypes.UserPermissions).getValues())
	// ASSUMPTION: Region suffix are only two characters long and have a leading `_`.
	.groupBy((value) => _.toUpper(value.slice(value.length - 2)))
	.mapValues((permissions) => {
		return _.keyBy(permissions, (permission) => permission.slice(0, permission.length - 3));
	})
	.value() as Record<
	Regions,
	Record<RegionAgnosticPermission<ApiModelTypes.UserPermissions>, ApiModelTypes.UserPermissions>
>;
