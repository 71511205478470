import { styled, Switch } from "@mui/material";

const smallSwitchDiameter = "25px";

const largeSwitchDiameter = "32px";

export const AdaSwitchElement = styled(Switch, {
	shouldForwardProp: (prop) => prop !== "smallVariant"
})<{ smallVariant?: boolean }>(({ smallVariant, theme }) => ({
	alignSelf: "flex-end",
	width: smallVariant ? "46px" : "54px",
	height: smallVariant ? "30px" : "34px",
	padding: 7,
	"& .MuiSwitch-switchBase": {
		top: smallVariant ? "1px" : "0",
		width: smallVariant ? smallSwitchDiameter : largeSwitchDiameter,
		height: smallVariant ? smallSwitchDiameter : largeSwitchDiameter,
		margin: 1,
		padding: 0,
		backgroundColor: theme.palette.primary.light,
		"& svg": {
			color: theme.palette.primary.contrastText
		},
		"&:hover": {
			// The switch thumb is a button base so it has a default hover, overwrite it
			backgroundColor: theme.palette.primary.light
		}
	},
	"& .MuiSwitch-switchBase.Mui-checked": {
		backgroundColor: theme.palette.primary.main,
		"&:hover": {
			backgroundColor: theme.palette.primary.main
		}
	}
}));
