import React from "react";
import * as ApiModelTypes from "../../api/apiModelTypes";

/**
 * Name for this component.
 */
const COMPONENT_NAME = "UserPermissionsContext";

export const UserPermissionsContext = React.createContext<Array<ApiModelTypes.UserPermissions>>([]);
UserPermissionsContext.displayName = COMPONENT_NAME;
