import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { App } from "./components/App";

const container = document.getElementById("root");

// Create a root.
const root = createRoot(container!);

root.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>
);

// Set up service workers
if ("serviceWorker" in navigator) {
	window.addEventListener("load", () => {
		navigator.serviceWorker
			.register("/serviceWorker.js", { scope: "/" })
			.then((registration: ServiceWorkerRegistration) => {
				// do nothing for now
			})
			.catch((registrationError: any) => {
				// we'll need this to help us confirm PWA-related issues in live environments
				console.log("SW did not register successfully: ", registrationError);
			});
	});
}
