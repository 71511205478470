import * as Material from "@mui/material";

export const LandingPage = Material.styled("div")(() => ({
	height: "100%",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	position: "relative"
}));

export const Paper = Material.styled(Material.Paper)(({ theme }) => ({
	padding: theme.spacing(4)
}));

export const AccessRequestView = Material.styled("div")(() => ({
	display: "flex",
	flexDirection: "column"
}));

export const AccessMatrixImage = Material.styled("img")(() => ({
	width: "100%",
	maxWidth: "507px"
}));

export const AccessRequestForm = Material.styled(Material.FormControl)(({ theme }) => ({
	marginTop: theme.spacing(4)
}));

export const AccessRequestSubmitButton = Material.styled(Material.Button)(({ theme }) => ({
	marginTop: theme.spacing(4),
	width: "max-content"
}));

export const AccessRequestFormTitle = Material.styled("div")(() => ({
	display: "flex",
	justifyContent: "center"
}));

export const AccessRequestFormCloseButton = Material.styled(Material.IconButton)(() => ({
	marginLeft: "auto"
}));

/**
 * Simple div for providing consistent spacing for the wrapped element
 */
export const TopSpacer = Material.styled("div")(({ theme }) => ({
	marginTop: theme.spacing(4)
}));

/**
 * Styles for the "Did You Know?" section
 */
export const DidYouKnowContainer = Material.styled(Material.Box)(({ theme }) => ({
	backgroundColor: "#f5f5f5",
	padding: theme.spacing(2),
	marginTop: theme.spacing(2),
}));

/**
 * Styles for the "Did You Know?" Title section
 */
export const DidYouKnowTitle = Material.styled(Material.Typography)(({ theme }) => ({
	fontWeight: "bold",
}));