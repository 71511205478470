import { Backdrop, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import "./LoadingMask.scss";

/**
 * Name for this component.
 */
const COMPONENT_NAME = "LoadingMask";

/**
 * Private styles for this component.
 */
const useStyles = makeStyles(() => ({
	backdrop: {
		zIndex: 100,
		color: "#fff"
	}
}));

/**
 * Props interface for this component.
 */
interface Props {
	/**
	 * Controls the open state of this mask.
	 */
	isOpen: boolean;
}

/**
 * A loading view to present to the user while data is loading.
 */
export const LoadingMask: React.FC<Props> = (props) => {
	const classes = useStyles();
	return (
		<Backdrop className={classes.backdrop} open={props.isOpen}>
			<CircularProgress color="inherit" />
		</Backdrop>
	);
};
LoadingMask.displayName = COMPONENT_NAME;
